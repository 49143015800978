import React, { useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";
import * as API from "../../lib/api";
import styled from "styled-components";

import { Helmet } from "react-helmet";

import SpinnerComponent from "../Shared/Spinners";
import LeftSidebar from "../Sidebars/LeftSidebar";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import Overview from './Overview';
import Drafts from '../Shared/Drafts';
import StatsByYear from './StatsByYear';
import StatsBySplit from './StatsBySplit';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 20em;
  max-width: 70em;
`;

const BioContainer = styled.div`
  width: 100%;
  min-height: 8em;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #000;
  margin-bottom: .2em;
  ${props => (props.hasLogo) ? 'background-image: linear-gradient(to right, white , white, black);' : null}
  font-size: 0.85em;
  
  @media (max-width: 768px) {
    min-height: 10em;
  }
`

const BioRightImage = styled.div`
  max-height: 8em;
  margin: 0 1em;
`

const TeamLogo = styled.img`
  max-height: 8em;
`

const BioMainInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: .2em;
`

const NameAndWiki = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const BioRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`

const BioBox = styled.div`
  margin-right: 2em;
  min-width: 6em;
  font-size: ${props => props.textSize === 'medium' ? '1em' : props.textSize === 'large' ? '1.6em' : '.9em'};
  font-weight: ${props => props.bold ? 'bold' : 'normal'};
  ${props => props.color ? 'color: ' + props.color + ';' : null}

  @media (max-width: 768px) {
    margin-right: .25em;
  }
`

const WikiLink = styled.div`
  margin: 0 2em;
  min-width: 6em;
  font-weight: bold;

  @media (max-width: 768px) {
    margin: 0 .25em 0 0;
  }
`

const TeamProfileTabNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 3px 0 5px 0;
  border: 1px solid #000000;
  font-size: 0.85em;
`

const TeamProfileTabNavItem = styled.div`
  max-width: 8em;
  padding: 5px;
  border-right: 1px solid ${props => props.active ? 'rgb(34, 143, 216)' : '#ccc'};
  color: ${props => props.active ? 'white' : 'black'};
  background-color: ${props => props.active ? 'rgb(34, 143, 216)' : 'transparent'};
  text-align: center;

  :hover {
    color: white;
    background-color: ${props => props.active ? 'rgb(34, 143, 216)' : 'rgb(34, 143, 216, .8)'};
    border-right: 1px solid ${props => props.active ? 'rgb(34, 143, 216)' : 'rgb(34, 143, 216, .8)'};
  }
`

const NoValue = styled.span`
  padding: 0 1em;
  text-align: center;
`

const TeamProfile = ({ match, AppStore }) => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
    )
    ? true
    : false;

  const teamId = match.params.id;
  const teamFetching = AppStore.teamProfileFetching.get();
  const teamFetched = Boolean(teamId in AppStore.teamProfileMap);

  const route = "teams";
  const didRouteChange = route !== AppStore.currentRoute.get();

  const tabInView = match.params.tab ? match.params.tab : 'overview';
  const didTabChange = tabInView !== AppStore.teamProfileTabInView.get();

  const mapSide = match.params.mapSide;

  const statsAreFetching = AppStore.statsAreFetching.get();
  const gamesAreFetching = AppStore.gamesAreFetching.get();

  const didTeamChange = teamId !== AppStore.teamProfileInView.get();

  const segment = teamId;

  const teamInfo = AppStore.teamProfileMap[teamId];

  const doStatsByYearNeedUpdating = 
    (AppStore.teamProfileDataMap[teamId] && !AppStore.teamProfileDataMap[teamId].statsByYear) &&
    !statsAreFetching;
  const doStatsBySplitNeedUpdating = 
    (AppStore.teamProfileDataMap[teamId] && !AppStore.teamProfileDataMap[teamId].statsBySplit) &&
    !statsAreFetching;
  const doGameDetailsNeedUpdating = 
    (AppStore.teamProfileDataMap[teamId] && !AppStore.teamProfileDataMap[teamId].gameDetails) &&
    !gamesAreFetching;

  const pageTitle = `${teamInfo
    ? teamInfo.name
    : "Fetching"
    } Team Profile - Oracle's Elixir`;
  const pageDescription = `Oracle's Elixir team profile page${teamInfo && " for " + teamInfo.name
    }.`;

  useEffect(() => {
    const fetchTeamProfileData = (async (teamId) => {
      AppStore.teamProfileFetching.set(true);
      const teamProfileData = await API.getTeamProfile(teamId);
      AppStore.addTeamProfileData(teamId, teamProfileData);
      AppStore.setTeamProfileInView(teamId);
      AppStore.teamProfileFetching.set(false);
    })

    const fetchTeamStatsByYear = (async (teamId) => {
      AppStore.setStatsInView(
        'teamProfile',
        'statsByYear',
        null,
        null,
        teamId,
        null,
        null,
        null
      );
    })
    
    const fetchTeamStatsBySplit = (async (teamId) => {
      AppStore.setStatsInView(
        'teamProfile',
        'statsBySplit',
        null,
        null,
        teamId,
        null,
        null,
        null
      );
    })

    const fetchTeamGameDetails = (async (teamId) => {
      AppStore.fetchGameDetails(
        'teamProfile',
        teamId
      );
    })

    const statsDefinitionsAreFetched = AppStore.statsDefinitionsAreFetched.get();
    const statsDefinitionsAreFetching = AppStore.statsDefinitionsAreFetching.get();

    if (!statsDefinitionsAreFetched && !statsDefinitionsAreFetching) {
      AppStore.getStatsDefinitions();
    }

    if (!AppStore.teamProfileMap[teamId] && !teamFetching) {
      fetchTeamProfileData(teamId);
    }

    if (!AppStore.teamProfileDataMap[teamId]) {
      AppStore.addTeamToProfileDataMap(teamId);
    }

    if (tabInView === 'statsByYear' && doStatsByYearNeedUpdating) {
      fetchTeamStatsByYear(teamId)
    }
    
    if (tabInView === 'statsBySplit' && doStatsBySplitNeedUpdating) {
      fetchTeamStatsBySplit(teamId)
    }

    if ((tabInView === 'overview' || tabInView === 'games') && doGameDetailsNeedUpdating) {
      fetchTeamGameDetails(teamId)
    }

    if ((mapSide !== AppStore.draftsMapSideInView.get()) || didTeamChange || didRouteChange) {
      AppStore.draftsMapSideInView.set(mapSide || 'all');
    }

    if (didTabChange || didRouteChange || didTeamChange) {
      AppStore.resetSort();
      AppStore.setTeamProfileInView(teamId);
      AppStore.setTeamProfileTabInView(tabInView);
      if (tabInView === 'overview' && AppStore.teamProfileDataMap[teamId].hasOwnProperty('gameDetails')) {
        AppStore.gamesInView.replace(AppStore.teamProfileDataMap[teamId].gameDetails);
      }
      if (tabInView === 'statsByYear' && AppStore.teamProfileDataMap[teamId].hasOwnProperty('statsByYear')) {
        AppStore.statsInView.replace(AppStore.teamProfileDataMap[teamId].statsByYear)
        if (AppStore.teamProfileDataMap[teamId].statsByYear.length > 0) {
          AppStore.setStatsColumnsInView();
          AppStore.filterStatsTable(AppStore.currentStatsFilters);
        }
      }
      if (tabInView === 'statsBySplit' && AppStore.teamProfileDataMap[teamId].hasOwnProperty('statsBySplit')) {
        AppStore.statsInView.replace(AppStore.teamProfileDataMap[teamId].statsBySplit)
        if (AppStore.teamProfileDataMap[teamId].statsBySplit.length > 0) {
          AppStore.setStatsColumnsInView();
          AppStore.filterStatsTable(AppStore.currentStatsFilters);
        }
      }
    }

    if (didTeamChange || didRouteChange) {
      AppStore.resetBasicFilters();
      AppStore.resetQueryFilters();
      AppStore.resetSoloQueueQueryFilters();
      AppStore.resetSort();
    }

    if (didRouteChange) {
      AppStore.setCurrentRoute(route);
    }
  }, ([
    teamId,
    segment,
    tabInView,
    match.params.mapSide,
    mapSide,
    didTeamChange,
    didTabChange,
    didRouteChange,
    teamFetching,
    teamFetched,
    AppStore,
    AppStore.teamProfileMap,
    AppStore.teamProfileDataMap,
    AppStore.gamesInView,
    AppStore.upcomingMatches,
    AppStore.statsInView,
    statsAreFetching,
    doStatsByYearNeedUpdating,
    doStatsBySplitNeedUpdating,
    doGameDetailsNeedUpdating
  ]))

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@TimSevenhuysen" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
      </Helmet>
      <LeftSidebar />
      <Wrapper className="mainContent">
        {teamFetching ? (
          <SpinnerComponent />
        ) : (
            teamFetched && (
              (!teamInfo) ? (
                <Redirect to={`/404?team/${teamId}`} />
              ) : (
                <>
                  <BioContainer
                    isMobile={isMobile}
                    hasLogo={teamInfo.logo}
                  >
                    <BioMainInfo hasLogo={teamInfo.logo}>
                      <NameAndWiki>
                        <BioBox textSize="large" bold>{teamInfo.name} {(teamInfo.tag) && `(${teamInfo.tag})`}</BioBox>
                        <WikiLink>
                          <a href={`${teamInfo.gamepediaLink}`} target="_blank" rel="noopener noreferrer">Wiki Page {<FontAwesomeIcon icon={faExternalLinkAlt} />}</a>
                        </WikiLink>
                      </NameAndWiki>
                      <BioRow>
                        {(teamInfo.active === 'No') ? (
                          <BioBox color='#D86B22'>
                            <b>Disbanded</b>
                          </BioBox>
                        ) : (
                          <BioBox>
                            <b>League:</b> {teamInfo.league ? teamInfo.league : <NoValue>-</NoValue>}
                          </BioBox>
                        )}
                      </BioRow>
                      <BioRow>
                        <BioBox>
                          <b>Region:</b> {teamInfo.region ? teamInfo.region : <NoValue>-</NoValue>}
                        </BioBox>
                      </BioRow>
                    </BioMainInfo>
                    {teamInfo.logo && (
                      <BioRightImage>
                        <TeamLogo
                          src={`https://${process.env.REACT_APP_TEAM_LOGOS_BUCKET}.s3-us-west-2.amazonaws.com/${teamInfo.logo}`}
                          alt={`Team logo for ${teamInfo.teamName}`}
                        />
                      </BioRightImage>
                    )}
                  </BioContainer>
                  <TeamProfileTabNav>
                    <Link to={`/team/${teamId}`}>
                      <TeamProfileTabNavItem
                        active={!match.params.tab || match.params.tab === 'overview'}
                      >
                        Overview
                      </TeamProfileTabNavItem>
                    </Link>
                    <Link to={`/team/${teamId}/drafts/${AppStore.draftsMapSideInView.get()}`}>
                      <TeamProfileTabNavItem
                        active={match.params.tab === 'drafts'}
                      >
                        Drafts
                      </TeamProfileTabNavItem>
                    </Link>
                    <Link to={`/team/${teamId}/statsByYear`}>
                      <TeamProfileTabNavItem
                        active={match.params.tab === 'statsByYear'}
                      >
                        Stats By Year
                      </TeamProfileTabNavItem>
                    </Link>
                    <Link to={`/team/${teamId}/statsBySplit`}>
                      <TeamProfileTabNavItem
                        active={match.params.tab === 'statsBySplit'}
                      >
                        Stats By Split
                      </TeamProfileTabNavItem>
                    </Link>
                    {/* <Link to={`/team/${teamId}/games`}>
                      <TeamProfileTabNavItem
                        active={match.params.tab === 'games'}
                      >
                        Games
                      </TeamProfileTabNavItem>
                    </Link> */}
                  </TeamProfileTabNav>
                  {
                    !(AppStore.currentRoute.get() === 'teams') ? (
                      <SpinnerComponent />
                    ) : (
                      (AppStore.teamProfileTabInView.get() === 'overview') ? (
                        <Overview teamId={teamId} tab={tabInView} />
                      ) : (AppStore.teamProfileTabInView.get() === 'drafts') ? (
                        <Drafts view='teamProfile' teamProfileId={teamId} tab={tabInView} />
                      ) : (AppStore.teamProfileTabInView.get() === 'statsByYear') ? (
                        <StatsByYear teamId={teamId} tab={tabInView} />
                      ) : (AppStore.teamProfileTabInView.get() === 'statsBySplit') ? (
                        <StatsBySplit teamId={teamId} tab={tabInView} />
                      ) /* : (AppStore.teamProfileTabInView.get() === 'games') ? (
                        <GameDetails teamId={teamId} tab={tabInView} />
                      ) */ : (
                        null
                      )
                    )
                  }
                </>
              )
            )
          )}
      </Wrapper>
    </>
  );
};

export default inject("AppStore")(observer(TeamProfile));
