import React, { useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";
import * as API from "../../lib/api";
import styled from "styled-components";
import Moment from "react-moment";

import { Helmet } from "react-helmet";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import SpinnerComponent from '../Shared/Spinners';
import LeftSidebar from '../Sidebars/LeftSidebar';

import Summary from './Summary.js';
import VOD from './VOD.js';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 20em;
  max-width: 70em;
  color: white;
`;

const HeaderContainer = styled.div`
  width: 100%;
  min-height: 8em;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #000;
  margin-bottom: .2em;
  font-size: 0.85em;
  background: black;
  
  @media (max-width: 1330px) {
    min-height: 10em;
  }
`

const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 8em;
  ${props => !props.hasLeagueLogo && 'padding-left: 1em;'}
`

const LeagueName = styled.div`
  font-size: 1.3em;
  font-weight: bold;
`

const BlockAndSeriesType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Block = styled.div`
  margin-right: 1em;
`

const SeriesType = styled.div`
  
`

const GameDate = styled.div`
  font-style: italic;
  font-size: 0.8em;
`

const GameState = styled.div`
  font-weight: bold;
  font-size: 1.1em;
  ${props => props.inProgress && 'animation: fadeIn 1.5s infinite alternate;'}
  ${props => props.inProgress && 'color: #34D822;'}

  @keyframes fadeIn { 
    from { opacity: 30%; } 
  }
`

const TeamsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 1em;

  @media (max-width: 1330px) {
    flex-direction: column;
    padding-left: 0;
  }
`

const HeaderTeamLeft = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 10em;

  @media (max-width: 1330px) {
    justify-content: flex-start;
    margin-bottom: 0.5em;
    min-width: 8em;
  }
`

const HeaderTeamRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 10em;

  @media (max-width: 1330px) {
    flex-direction: row-reverse;
    justify-content: flex-end;
    min-width: 8em;
  }
`

const HeaderTeamLogoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.side === 'left') ? 'flex-end' : (props.side === 'right') ? 'flex-start' : null};

  @media (max-width: 1330px) {
    justify-content: flex-start;
  }
`

const HeaderTeamTagContainer = styled.div`
  width: 12em;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1330px) {
    flex: 1;
    width: 6em;
  }
`

const HeaderTeamTag = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;

  & a {
    color: white;
  }

  @media (max-width: 768px) {
    font-size: 1.1em;
  }
`

const HeaderTeamNamePopover = styled(Popover.Content)`
  color: black;
`

const LeagueLogo = styled.img`
  max-height: 6em;
  width: auto;
  margin: 0 0.75em 0 0.5em;

  @media (max-width: 768px) {
    max-height: 6em;
  }
`

const TeamLogo = styled.img`
  max-width: 5em;
  height: auto;

  @media (max-width: 1330px) {
    height: 3.5em;
  }
`

const Versus = styled.div`
  @media (max-width: 1330px) {
    display: none;
  }
`

const TabNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 3px;
  border: 1px solid #000000;
  font-size: 0.85em;
`

const TabNavItem = styled.div`
  max-width: 8em;
  padding: 5px;
  border-right: 1px solid ${props => props.active ? 'rgb(34, 143, 216)' : '#ccc'};
  color: ${props => props.active ? 'white' : 'black'};
  background-color: ${props => props.active ? 'rgb(34, 143, 216)' : 'transparent'};
  text-align: center;
  ${props => props.inMatch && 'font-size: .8em;'}

  :hover {
    color: white;
    background-color: ${props => props.active ? 'rgb(34, 143, 216)' : 'rgb(34, 143, 216, .8)'};
    border-right: 1px solid ${props => props.active ? 'rgb(34, 143, 216)' : 'rgb(34, 143, 216, .8)'};
  }
`

const GameContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const GameDataUnavailable = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 10em;
  margin-top: 2em;
  color: black;
  font-size: 1.2em;
`

const SeriesTypeMap = {
  bestOf3: "Bo3",
  bestOf5: "Bo5",
  bestOf1: null
};

const GameStateMap = {
  completed: "Complete",
  inProgress: "In Progress",
  unstarted: "Upcoming"
};

const Game = ({ AppStore, match, matchId, gameIdFromMatch, gameNumber }) => {
  // NOTE: match is from router, meaning the path params etc.
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
    ? true
    : false;

  const gameId = matchId ? gameIdFromMatch : match.params.gameId;

  const gameFetching = AppStore.singleGameFetching.get();
  const gameFetched = Boolean(gameId in AppStore.gameMap);

  const gameNeedsNewFetch = AppStore.singleGameNeedsNewFetch.get();

  const route = "games";
  const didRouteChange = route !== AppStore.currentRoute.get();

  const tabInView = matchId
    ? (match.params.param2 ? match.params.param2 : 'overview')
    : (match.params.tab ? match.params.tab : 'overview')
    ;
  const didTabChange = tabInView !== AppStore.gameTabInView.get();

  const didGameChange = gameId !== AppStore.gameInView.get();

  const statsDefinitionsAreFetching = AppStore.statsDefinitionsAreFetching.get();
  const statsDefinitionsAreFetched = AppStore.statsDefinitionsAreFetched.get();

  const gameInfo = AppStore.gameMap[gameId];

  const team1Code = gameInfo?.blueTeam?.teamCode ? gameInfo.blueTeam.teamCode : 'TBD';
  const team1Name = gameInfo?.blueTeam?.teamCode ? gameInfo.blueTeam.teamName : 'TBD';
  const team2Code = gameInfo?.redTeam?.teamCode ? gameInfo.redTeam.teamCode : 'TBD';
  const team2Name = gameInfo?.redTeam?.teamCode ? gameInfo.redTeam.teamName : 'TBD';

  const pageTitle = gameInfo ? `${team1Code ? team1Code : 'TBD'} vs. ${team2Code ? team2Code : 'TBD'}, ${gameInfo?.metadata?.league?.label} - Oracle's Elixir` : 'Loading...';
  const pageDescription = gameInfo ? `Game details for ${team1Code ? team1Code : 'TBD'} vs. ${team2Code ? team2Code : 'TBD'}, ${gameInfo?.metadata?.league?.label}` : 'Loading...';

  useEffect(() => {
    const fetchGameData = (async (gameId) => {
      AppStore.singleGameFetching.set(true);
      const gameData = await API.getSingleGame(gameId);
      if (Object.prototype.toString.call(gameData) === '[object Error]') {
        AppStore.addGameData(gameId, 'data unavailable');
      } else {
        AppStore.addGameData(gameId, gameData);
      }
      AppStore.setGameInView(gameId);
      AppStore.singleGameFetching.set(false);
      if (gameData.state === 'inProgress') {
        setTimeout(() => AppStore.singleGameNeedsNewFetch.set(true), 60000);
        // This still needs some testing and observation to make sure it's working
      }
    })

    if ((!AppStore.gameMap[gameId] && !gameFetching) || (gameNeedsNewFetch && !gameFetching)) {
      fetchGameData(gameId);
    }

    if (!statsDefinitionsAreFetched && !statsDefinitionsAreFetching) {
      AppStore.getStatsDefinitions();
    }

    if (didTabChange || didGameChange || didRouteChange) {
      AppStore.setGameInView(gameId);
      AppStore.setGameTabInView(tabInView);
    }

    if (didRouteChange && AppStore.currentRoute.get() !== 'matches') {
      AppStore.setCurrentRoute(route);
    }
  }, ([
    AppStore,
    gameId,
    matchId,
    gameNumber,
    tabInView,
    didGameChange,
    didRouteChange,
    didTabChange,
    gameNeedsNewFetch,
    gameFetching,
    gameFetched,
    statsDefinitionsAreFetched,
    statsDefinitionsAreFetching
  ]))

  return (
    ((!matchId || (matchId && match.params.tab === 'games')) ? (
      <>
        {!matchId && (
          <>
            <Helmet>
              <title>{pageTitle}</title>
              <meta name="description" content={pageDescription} />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:site" content="@TimSevenhuysen" />
              <meta name="twitter:title" content={pageTitle} />
              <meta name="twitter:description" content={pageDescription} />
            </Helmet>
            <LeftSidebar />
          </>
        )}
        <Wrapper
          className={matchId ? null : `mainContent`}
        >
          {!gameFetched ? (
            <SpinnerComponent />
          ) : gameInfo === 'data unavailable' ? (
            <GameDataUnavailable>Game data not (yet) available</GameDataUnavailable>
          ) : (
            gameFetched && (
              (!gameInfo) ? (
                <Redirect to={`/404?games/${gameId}`} />
              ) : (
                <>
                  {!matchId && (
                    <HeaderContainer>
                      {gameInfo.metadata.league.image && (
                        <LeagueLogo
                          src={`https://${process.env.REACT_APP_LEAGUE_LOGOS_BUCKET}.s3.us-west-2.amazonaws.com/${gameInfo.metadata.league.image}`}
                          alt={`League logo for ${gameInfo.metadata.league.name}`}
                        />
                      )}
                      <MainInfo hasLeagueLogo={Boolean(gameInfo.metadata.league.image)}>
                        <LeagueName>
                          {
                            isMobile ? (
                              gameInfo.metadata.league.label
                            ) : (
                              gameInfo.metadata.league.name
                            )
                          }
                        </LeagueName>
                        <BlockAndSeriesType>
                          <Block>{gameInfo.metadata.blockName}</Block>
                          <SeriesType>{SeriesTypeMap[gameInfo.metadata.seriesType]}</SeriesType>
                        </BlockAndSeriesType>
                        <GameDate>
                          {
                            <Moment
                              date={gameInfo.startTime}
                              format="MMM D, YYYY h:mm A"
                            />
                          }
                        </GameDate>
                        <GameState inProgress={Boolean(gameInfo.metadata.state === 'inProgress')}>
                          {GameStateMap[gameInfo.metadata.state]}
                        </GameState>
                      </MainInfo>
                      <TeamsContainer>
                        <HeaderTeamLeft>
                          <HeaderTeamLogoContainer side={isMobile ? null : 'left'}>
                            {
                              gameInfo.blueTeam.teamImage ? (
                                (gameInfo.blueTeam.teamId)
                                  ? (
                                    <Link to={`/team/${gameInfo.blueTeam.teamId}`}>
                                      <TeamLogo
                                        src={`https://${process.env.REACT_APP_TEAM_LOGOS_BUCKET}.s3.us-west-2.amazonaws.com/${gameInfo.blueTeam.teamImage}`}
                                        alt={`Team logo for ${team1Name}`}
                                      />
                                    </Link>
                                  ) : (
                                    <TeamLogo
                                      src={`https://${process.env.REACT_APP_TEAM_LOGOS_BUCKET}.s3.us-west-2.amazonaws.com/${gameInfo.blueTeam.teamImage}`}
                                      alt={`Team logo for ${team1Name}`}
                                    />
                                  )
                              ) : (
                                team1Code !== 'TBD' ? team1Code : null
                              )
                            }
                          </HeaderTeamLogoContainer>
                          <HeaderTeamTagContainer side='left'>
                            <OverlayTrigger
                              trigger={['hover', 'focus']}
                              delay={{ show: 300, hide: 200 }}
                              key={`${team1Code}-left`}
                              placement='top'
                              overlay={
                                <Popover id={`popover-${team1Code}-left`}>
                                  <HeaderTeamNamePopover>
                                    {gameInfo.blueTeam.teamName}
                                  </HeaderTeamNamePopover>
                                </Popover>
                              }
                            >
                              <HeaderTeamTag>
                                {
                                  (gameInfo.blueTeam.teamId && gameInfo.blueTeam.teamId !== 'TBD')
                                    ? (
                                      <Link to={`/team/${gameInfo.blueTeam.teamId}`}>{gameInfo.blueTeam.teamCode}</Link>
                                    ) : (
                                      team1Code
                                    )
                                }
                              </HeaderTeamTag>
                            </OverlayTrigger>
                          </HeaderTeamTagContainer>
                        </HeaderTeamLeft>
                        {
                          !isMobile ? (
                            <Versus>vs</Versus>
                          ) : (
                            null
                          )
                        }
                        <HeaderTeamRight>
                          <HeaderTeamTagContainer side='right'>
                            <OverlayTrigger
                              trigger={['hover', 'focus']}
                              delay={{ show: 300, hide: 200 }}
                              key={`${team2Code}-right`}
                              placement='top'
                              overlay={
                                <Popover id={`popover-${team2Code}-right`}>
                                  <HeaderTeamNamePopover>
                                    {team2Name}
                                  </HeaderTeamNamePopover>
                                </Popover>
                              }
                            >
                              <HeaderTeamTag>
                                {
                                  (gameInfo.redTeam.teamId && gameInfo.redTeam.teamId !== 'TBD')
                                    ? (
                                      <Link to={`/team/${gameInfo.redTeam.teamId}`}>{gameInfo.redTeam.teamCode}</Link>
                                    ) : (
                                      team2Code
                                    )
                                }
                              </HeaderTeamTag>
                            </OverlayTrigger>
                          </HeaderTeamTagContainer>
                          <HeaderTeamLogoContainer side={isMobile ? null : 'right'}>
                            {
                              gameInfo.redTeam.teamImage ? (
                                (gameInfo.redTeam.teamId)
                                  ? (
                                    <Link to={`/team/${gameInfo.redTeam.teamId}`}>
                                      <TeamLogo
                                        src={`https://${process.env.REACT_APP_TEAM_LOGOS_BUCKET}.s3.us-west-2.amazonaws.com/${gameInfo.redTeam.teamImage}`}
                                        alt={`Team logo for ${gameInfo.redTeam.teamName}`}
                                      />
                                    </Link>
                                  ) : (
                                    <TeamLogo
                                      src={`https://${process.env.REACT_APP_TEAM_LOGOS_BUCKET}.s3.us-west-2.amazonaws.com/${gameInfo.redTeam.teamImage}`}
                                      alt={`Team logo for ${gameInfo.redTeam.teamName}`}
                                    />
                                  )
                              ) : (
                                team2Code !== 'TBD' ? team2Code : null
                              )
                            }
                          </HeaderTeamLogoContainer>
                        </HeaderTeamRight>
                      </TeamsContainer>
                    </HeaderContainer>
                  )}
                  <TabNav>
                    <Link to={matchId ? `/matches/${matchId}/games/${gameNumber}` : `/games/${gameId}`}>
                      <TabNavItem
                        active={matchId ? (match.params.param1 === gameNumber && AppStore.gameTabInView.get() === 'overview') : (!match.params.tab || match.params.tab === 'overview')}
                        inMatch={Boolean(matchId)}
                      >
                        Summary
                      </TabNavItem>
                    </Link>
                    {(gameInfo.metadata.vod !== null && gameInfo.metadata.vod !== '[]') && (
                      <Link to={matchId ? `/matches/${matchId}/games/${gameNumber}/vod` : `/games/${gameId}/vod`}>
                        <TabNavItem
                          active={matchId ? (match.params.param1 === gameNumber && AppStore.gameTabInView.get() === 'vod') : match.params.tab === 'vod'}
                          inMatch={Boolean(matchId)}
                        >
                          VOD
                        </TabNavItem>
                      </Link>
                    )}
                  </TabNav>
                  {
                    !(AppStore.currentRoute.get() === 'games' || (AppStore.currentRoute.get() === 'matches' && matchId)) ? (
                      <SpinnerComponent />
                    ) : (
                      <GameContainer>
                        {
                          tabInView === 'overview' ? (
                            <Summary gameInfo={gameInfo} />
                          ) : tabInView === 'vod' ? (
                            <VOD vodUrl={gameInfo.metadata.vod} />
                          ) : (
                            null
                          )}
                      </GameContainer>
                    )
                  }
                </>
              )
            )
          )}
        </Wrapper>
      </>
    ) : (
      <SpinnerComponent />
    ))
  );
};

export default inject("AppStore")(observer(Game));
