import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import { digitCounts } from '../../static/fixedDigits';

const StatsRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const StatsValue = styled.div`
  flex: ${props => props.flex ? props.flex : 1};
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.align};
  align-items: center;
  font-weight: ${props => props.valueIndex === 0 ? 'bold' : 'normal'};
  font-size: .65em;
  padding-left: ${props => props.align === 'left' ? '3px' : '1px'};
  min-width: ${props => props.minWidth ? props.minWidth : '5em'};
  min-height: 2.5em;
  position: ${props => props.valueIndex === 0 ? '-webkit-sticky' : 'static'};
  position: ${props => props.valueIndex === 0 ? 'sticky' : 'static'};
  left: ${props => props.valueIndex === 0 ? '0' : 'auto'};
  border-bottom: 1px solid #ccc;
  border-right: 1px dashed #ccc;
  background-color: ${props => props.hovered ? '#F9E9DE' : props.rowIndex % 2 > 0 ? '#DEEEF9' : 'white'};

  & a {
      color: black;

      :hover {
          color: #228fd8;
          opacity: .8;
      }
  }
`

const ChampionImage = styled.img`
    width: 2em;
    height: 2em;
    margin-right: .5em;
`

const calcMinWidth = (category, valueIndex, view) => {
  if (valueIndex === 0) {
    return (category === 'teams' || category === 'champions') ? '10em' : '7.5em';
  } else if (valueIndex === 1 && view === 'soloQueueMatchups') {
    return '9em';
  } else if (valueIndex === 1 && category === 'players') {
    return '11em';
  }
}

const StatsRow = (props) => {
  const [hovered, setHovered] = useState(false);

  return (
    <StatsRowContainer
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {props.AppStore.statsColumnsInView.map((stat, valueIndex) => {
        var statValue = null;
        const statIsPopulated = props.stats[stat] != null;
        if (!statIsPopulated) {
          statValue = "-";
        } else if (
          typeof props.stats[stat] === "number" &&
          stat in digitCounts
        ) {
          statValue = props.stats[stat].toFixed(digitCounts[stat]);
        } else {
          statValue = props.stats[stat];
        }

        return (
          <StatsValue
            key={`${stat}`}
            rowIndex={props.index}
            valueIndex={valueIndex}
            hovered={hovered}
            label={`${stat}`}
            align={
              valueIndex === 0 ||
              (props.category === "players" && valueIndex === 1) ||
              (props.view === "soloQueueMatchups" && valueIndex === 1)
                ? "left"
                : "center"
            }
            minWidth={calcMinWidth(props.category, valueIndex, props.view)}
          >
            {((props.category === "champions" && stat === "Champion") || (props.view === "soloQueueMatchups" && stat === "Matchup")) && (
              <ChampionImage
                src={`https://${process.env.REACT_APP_CHAMPION_IMAGES_BUCKET}.s3-us-west-2.amazonaws.com/${props.stats[stat] !== 'Nunu' ? props.stats[stat] : 'Nunu & Willump'}.png`}
                alt={`${props.stats.stat}`}
              />
            )}
            {(props.category === "players" && valueIndex === 0 && props.stats.id) ? (
              <Link to={`/player/${props.stats.id}`}>{statValue}</Link>
            ) : (props.category === "teams" && valueIndex === 0 && props.stats.id) ? (
              <Link to={`/team/${props.stats.id}`}>{statValue}</Link>
            ) : (
              statValue
            )}
          </StatsValue>
        );
      })}
    </StatsRowContainer>
  );
};

export default inject('AppStore')(observer(StatsRow))