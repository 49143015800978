import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";

import { digitCounts } from '../../static/fixedDigits';

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
`

const ChampionPoolStatBox = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.index === 0 ? 'flex-start' : 'center'};
    padding-left: ${props => props.index === 0 ? '3px' : null};
    align-items: center;
    text-align: ${props => props.index === 0 ? 'left' : 'center'};
    min-width: ${props => props.index === 0 ? '10.5em' : '5em'};
    min-height: 2.5em;
    border-bottom: 1px solid #ccc;
    border-right: 1px dashed #ccc;
    background-color: ${props => props.hovered ? '#F9E9DE' : props.rowIndex % 2 > 0 ? '#DEEEF9' : 'white'};
    font-weight: ${props => props.index === 0 ? 'bold' : 'normal'};
    position: ${props => props.index === 0 ? '-webkit-sticky' : 'static'};
    position: ${props => props.index === 0 ? 'sticky' : 'static'};
    left: ${props => props.index === 0 ? '0' : 'auto'};
`

const ChampionImage = styled.img`
    width: 2em;
    height: 2em;
    margin-right: .5em;
`

const ChampionPoolRow = (props) => {
    const [hovered, setHovered] = useState(false);
    return (
        <RowContainer
            key={props.row.Champion}
            rowIndex={props.rowIndex}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            {props.AppStore.statsColumnsInView.map((stat, index) => {
                var statValue = null;
                const statIsPopulated = props.row[stat] != null;
                if (!statIsPopulated) {
                    statValue = "-";
                } else if (
                    typeof props.row[stat] === "number" &&
                    stat in digitCounts
                ) {
                    statValue = props.row[stat].toFixed(digitCounts[stat]);
                } else {
                    statValue = props.row[stat];
                }
                return (
                    <ChampionPoolStatBox
                        key={`${props.row.Champion}-${stat}`}
                        index={index}
                        rowIndex={props.rowIndex}
                        hovered={hovered}
                    >
                        {(index === 0) ? (
                            <ChampionImage
                                src={`https://${process.env.REACT_APP_CHAMPION_IMAGES_BUCKET}.s3-us-west-2.amazonaws.com/${props.row.Champion !== 'Nunu' ? props.row.Champion : 'Nunu & Willump'}.png`}
                                alt={`${props.row.Champion}`}
                            />
                        ) : null}
                        {statValue}
                    </ChampionPoolStatBox>
                )
            })}
        </RowContainer>
    )
}

export default inject("AppStore")(observer(ChampionPoolRow));