import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import SpinnerComponent from "../Shared/Spinners";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Wrapper = styled.div`
  width: 100%;
  margin-top: 1em;

  @media (max-width: 992px) {
    display: none;
  }
`

const Title = styled.div`
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: bold;

  &:first-child {
    margin-top: 0;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  & a {
    color: black;
  }
`

const VodContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: .9rem;
`

const League = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 3.5em;
  padding-left: .5em;
  font-weight: bold;
  color: white;
  background-image: linear-gradient(to right, black, black, black, white);
`

const Logo = styled.img`
  width: 1.5em;
  height: 1.5em;
  margin-left: .1em;
`

const Matchup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 .5em;
  
`

const Team = styled.div`
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;

`

const TeamNamePopover = styled(Popover.Content)`
  color: black;
`

const Versus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: .9em;
  font-weight: bold;
  margin: 0 1em;
`

const VodLink = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: #228fd8;
  width: 2em;
`

const RecentVods = ({ AppStore }) => {
  const recentVodsAreFetched = AppStore.recentVodsAreFetched.get();
  const recentVodsAreFetching = AppStore.recentVodsAreFetching.get();
  const vods = AppStore.recentVods;

  useEffect(() => {
    if (!recentVodsAreFetched && !recentVodsAreFetching) {
      AppStore.fetchRecentVods();
    }
  }, [
    AppStore,
    recentVodsAreFetched,
    recentVodsAreFetching
  ]);

  return (
    <Wrapper>
      <Title>
        Recent VODs
      </Title>
      <>
        <ContentContainer>
          {!recentVodsAreFetched ? (
            <SpinnerComponent />
          ) : (
            vods.map((vod, idx) => {
              var constructedUrl = false;
              if (vod.vod.substring(0, 2) === '[{') {
                const vodJson = JSON.parse(vod.vod);
                const startTimeInSeconds = vodJson[0].startMillis / 1000;
                const hours = Math.floor(startTimeInSeconds / 3600);
                const minutes = Math.floor(startTimeInSeconds % 3600 / 60);
                const seconds = Math.floor(startTimeInSeconds % 60);
                switch (vodJson[0].provider) {
                  case 'twitch':
                    constructedUrl = `https://www.twitch.tv/videos/${vodJson[0].parameter}?t=${hours}h${minutes}m${seconds}s`
                    break;
                  case 'youtube':
                    constructedUrl = `https://youtube.com/watch?v=${vodJson[0].parameter}t=${hours}h${minutes}m${seconds}s`
                    break;
                  default:
                  // No default needed
                }
              }

              return (
                <Link
                  to={vod.matchId ? `/matches/${vod.matchId}/games/${vod.gameInMatch}/vod` : `/games/${vod.oeGameId}/vod`}
                  key={`vod-link-${idx}-vod.vod`}
                >
                  <VodContainer
                    key={`vod-${idx}-${vod.vod}`}
                  >
                    <Matchup>
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        delay={{ show: 300, hide: 200 }}
                        key={vod.team1Name}
                        placement='top'
                        overlay={
                          <Popover id={`popover-${vod.team1Name}`}>
                            <TeamNamePopover>
                              {vod.team1Name}
                            </TeamNamePopover>
                          </Popover>
                        }
                      >
                        <Team side='left'>
                          {vod.team1Tag}
                          {vod.team1Logo && (
                            <Logo
                              src={`https://${process.env.REACT_APP_TEAM_LOGOS_BUCKET}.s3-us-west-2.amazonaws.com/${vod.team1Logo}`}
                              alt={`Team logo for ${vod.team1Name}`}
                            />
                          )}
                        </Team>
                      </OverlayTrigger>
                      <Versus>vs.</Versus>
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        delay={{ show: 300, hide: 200 }}
                        key={vod.team2Name}
                        placement='top'
                        overlay={
                          <Popover id={`popover-${vod.team2Name}`}>
                            <TeamNamePopover>
                              {vod.team2Name}
                            </TeamNamePopover>
                          </Popover>
                        }
                      >
                        <Team side='right'>
                          {vod.team2Logo && (
                            <Logo
                              src={`https://${process.env.REACT_APP_TEAM_LOGOS_BUCKET}.s3-us-west-2.amazonaws.com/${vod.team2Logo}`}
                              alt={`Team logo for ${vod.team2Name}`}
                            />
                          )}
                          {vod.team2Tag}
                        </Team>
                      </OverlayTrigger>
                    </Matchup>
                    <VodLink>
                      <FontAwesomeIcon icon={
                        constructedUrl ? (
                          constructedUrl.search("twitch") > -1 ? ["fab", "twitch"] : ["fab", "youtube"]
                        ) : (
                          vod.vod.search("twitch") > -1 ? ["fab", "twitch"] : ["fab", "youtube"]
                        )
                      } />
                    </VodLink>
                  </VodContainer>
                </Link>
              )
            })
          )}
        </ContentContainer>
      </>
    </Wrapper>
  )
}

export default inject("AppStore")(observer(RecentVods));